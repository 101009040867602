import { graphql, useStaticQuery } from "gatsby"

const useContentfulFaq = locale => {
  const data = useStaticQuery(graphql`
    query {
      sk: allContentfulFaq(sort: { fields: createdAt, order: ASC }) {
        edges {
          node {
            question: questionSk
            answer: answerSk
            category: categorySk
            sort: sort
          }
        }
      }
      en: allContentfulFaq(sort: { fields: createdAt, order: ASC }) {
        edges {
          node {
            question: questionEn
            answer: answerEn
            category: categoryEn
            sort: sort
          }
        }
      }
    }
  `)
  return data[locale].edges.map(faq => ({
    question: faq.node.question,
    answer: faq.node.answer,
    category: faq.node.category,
    sort: faq.node.sort,
  }))
}

export default useContentfulFaq
