import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import tw from "twin.macro"

import arrow from "../images/icons/arrow-down.svg"

const AccordionWrapper = styled.div`
  ${tw`rounded-30 md:rounded-40 shadow-values py-5 md:py-8 px-6 md:px-10 bg-white mt-5 mb-8 md:mb-12 cursor-pointer`}
`

const AccordionTitleSection = styled.div`
  ${tw`flex justify-between items-center`}
`

const AccrodionTitle = styled.h1`
  ${tw`text-base md:text-22 pr-6 font-semibold text-dark leading-32`}
`
const Icon = styled.img`
  ${tw`duration-500`}
  transition-delay: 100ms;
`

const AccordionDescriptionSection = styled.div`
  overflow: hidden;
  transition: max-height 0.5s ease-in-out !important;
  height: auto;
`

const AccordionDescription = styled.h1`
  ${tw`pt-6 font-light text-dark text-base md:text-18`}
`

const Accordion = ({ title, description }) => {
  const [open, setOpen] = useState(true)
  const [open2, setOpen2] = useState()
  const [height, setHeight] = useState(null)
  const elRef = useRef()

  useEffect(() => {
    setHeight(`${elRef.current.clientHeight}px`)
    setOpen(false)
  }, [])

  function handleChange() {
    setOpen(!open)
    setOpen2(!open)
  }

  return (
    <AccordionWrapper onClick={() => handleChange()}>
      <AccordionTitleSection>
        <AccrodionTitle>{title}</AccrodionTitle>
        <Icon
          src={arrow}
          alt="arrow"
          style={{
            transform: open2 ? "rotate(180deg)" : "rotate(0deg)",
          }}
        ></Icon>
      </AccordionTitleSection>
      <AccordionDescriptionSection
        style={{ maxHeight: `${open ? height : "0px"}` }}
        ref={elRef}
      >
        <AccordionDescription className="accordion-content">
          {description}
        </AccordionDescription>
      </AccordionDescriptionSection>
    </AccordionWrapper>
  )
}

export default Accordion
