import React, { useState } from "react"
import tw from "twin.macro"
import styled from "styled-components"
import { Link, FormattedMessage, useIntl } from "gatsby-plugin-intl"
import Slider from "react-slick"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import Head from "../components/head"
import FaqAccordion from "../components/faqAccordion"

import useContentfulFaq from "../hooks/use-contentful-faq"
import { SmoothVerticalScrolling } from "../utils/scroll"

import paperPlane from "../images/icons/paper-plane.svg"
import arrow from "../images/icons/arrow-down.svg"

const FAQSection = styled.div`
  ${tw`bg-light pt-12 md:pt-32 pb-14`}
`

const Container = styled.div`
  ${tw`px-5 relative max-w-default w-full flex`};
  margin: 0 auto;
`
const FAQContainer = styled.div`
  ${tw`px-5 relative max-w-default w-full justify-between flex flex-col md:flex-row`};
  margin: 0 auto;
`

const IntroductionContent = styled.div`
  ${tw` px-5 relative lg:items-center pt-4 sm:pt-6 md:pt-12 lg:pt-20 pb-7 sm:pb-10 md:pb-16 lg:pb-24  max-w-default  flex flex-col lg:flex-row w-full`};
  margin: 10px auto 14px;
`
const Title = styled.h1`
  ${tw`text-40 sm:text-5xl md:text-xxl leading-snug w-full md:w-2/5 font-semibold text-dark`}
  max-width: 430px;
`

const DescriptionWrapper = styled.div`
  ${tw`w-full lg:w-3/5 pt-8 lg:pt-0`}
  max-width: 710px;
`

const Description = styled.p`
  ${tw`text-base md:text-lg text-dark font-light lg:pl-12 leading-testimonial`}
`

const ImageDivider = styled(Img)`
  ${tw`w-full`}
  max-height:360px;
  @media only screen and (max-width: 1440px) {
    max-height: 300px;
  }
`

const FilterWrapper = styled.div`
  ${tw`hidden md:block w-full mt-5 lg:w-2/5`}
  max-width: 264px;
`

const FilterWrapperMobile = styled(Slider)`
  ${tw`flex outline-none focus:outline-none md:hidden items-center pb-6 mb-1`}
`

const FilterLabel = styled.div`
  ${tw`pb-8 flex pt-7 cursor-pointer`}
  border-bottom: 2px solid #ABAFB5;
`

const FilterLabelMobile = styled.div`
  ${tw`flex items-center whitespace-no-wrap mr-5 outline-none focus:outline-none cursor-pointer`}
  display:flex !important;
`

const FilterImgMobile = styled.img`
  ${tw`mr-3`}
`
const FilterTextMobile = styled.p`
  ${tw`mr-2`}
`

const FilterImg = styled.img`
  ${tw``}
`

const FilterText = styled.p`
  ${tw`text-22 pl-5 text-dark leading-32`}
  margin-top: 3px;
`

const AccordionWrapper = styled.div`
  ${tw` ml-0 md:ml-16 w-full`}
  max-width:750px
`

const FooterSection = styled.div`
  ${tw`bg-light pt-0 md:pt-14  pb-26 md:pb-32`}
`

const FooterWrapper = styled.div`
  ${tw`flex`}
`

const FooterContent = styled.div`
  ${tw`pr-0 md:pr-32`}
`

const FooterText = styled.p`
  ${tw`text-dark text-base md:text-lg font-normal md:font-semibold leading-32 pt-2 md:pt-8 pb-10 md:pb-16`}
  max-width: 600px;
`

const FooterButton = styled(Link)`
  ${tw`text-dark text-lg font-semibold bg-primary px-12 py-3 rounded-full focus:outline-none hover:bg-primaryHovered duration-200 whitespace-no-wrap`}
  display: inline-block;
`

const FooterIllustration = styled.img`
  ${tw`ml-5 hidden md:block`}
`

const ScrollToTop = styled.div`
  ${tw`absolute cursor-pointer flex md:hidden items-center bottom-0 right-0 mr-5 opacity-40 mb-8`}
`

const ScrollToTopText = styled.p`
  ${tw`text-dark font-semibold pr-3`}
`

const IconArrow = styled.img`
  ${tw``}
  transform: rotate(180deg)
`
const DescriptionLink = styled(Link)`
  ${tw`underline`}
`

const FAQPage = () => {
  const intl = useIntl()
  const FaqPosts = useContentfulFaq(intl.locale)
  const [category, setCategory] = useState(FaqPosts[0].category)

  const settings = {
    className: "slider variable-width",
    dots: false,
    infinite: false,
    centerMode: false,
    focusOnSelect: true,
    arrows: false,
    slidesToShow: 1,
    variableWidth: true,
  }

  function groupBy(objectArray, property) {
    return objectArray.reduce(function(acc, obj) {
      let key = obj[property]
      if (!acc[key]) {
        acc[key] = []
      }
      acc[key].push(obj)
      return acc
    }, {})
  }

  let faqs = { ...groupBy(FaqPosts, "category") }

  function handleClick(cat) {
    setCategory(cat)
  }

  const scrollTop = () => {
    SmoothVerticalScrolling(document.getElementById("top-nav"), 400, "top")
  }

  const { image } = useStaticQuery(graphql`
    query {
      image: file(relativePath: { eq: "img-faq.png" }) {
        sharp: childImageSharp {
          fluid(maxWidth: 1440, quality: 97) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Layout>
      <Head
        lang={intl.locale}
        title={intl.formatMessage({ id: "navigation.faq" })}
      />
      <IntroductionContent>
        <Title>
          <FormattedMessage id="faq.title" />
        </Title>
        <DescriptionWrapper>
          <Description>
            <FormattedMessage id="faq.description_part_1" />
            <b>
              <FormattedMessage id="faq.description_part_bold" />
            </b>
            <FormattedMessage id="faq.description_part_2" />
            <DescriptionLink to="/contact">
              <FormattedMessage id="faq.description_part_link" />
            </DescriptionLink>
            <FormattedMessage id="faq.description_part_3" />
          </Description>
        </DescriptionWrapper>
      </IntroductionContent>
      <ImageDivider
        Tag="div"
        alt="FAQ"
        objectFit="cover"
        fluid={image.sharp.fluid}
        fadeIn={true}
      ></ImageDivider>
      <FAQSection id="faq-section">
        <FAQContainer>
          <FilterWrapper>
            {Object.entries(faqs).map((cat, i) => {
              return (
                <FilterLabel key={i} onClick={() => handleClick(cat[0])}>
                  <FilterImg
                    style={{
                      opacity: category === cat[0] ? "1" : ".4",
                    }}
                    src={`/icons/desktop/${cat[1][0].sort}.svg`}
                  ></FilterImg>
                  <FilterText
                    style={{
                      opacity: category === cat[0] ? "1" : ".4",
                      fontWeight: category === cat[0] ? "600" : "300",
                    }}
                  >
                    {cat[0]}
                  </FilterText>
                </FilterLabel>
              )
            })}
          </FilterWrapper>
          <FilterWrapperMobile {...settings}>
            {Object.entries(faqs).map((cat, i) => {
              return (
                <FilterLabelMobile key={i} onClick={() => handleClick(cat[0])}>
                  <FilterImgMobile
                    style={{
                      opacity: category === cat[0] ? "1" : ".4",
                    }}
                    src={`/icons/mobile/${cat[1][0].sort}.svg`}
                  ></FilterImgMobile>
                  <FilterTextMobile
                    style={{
                      opacity: category === cat[0] ? "1" : ".4",
                      fontWeight: category === cat[0] ? "600" : "300",
                    }}
                  >
                    {cat[0]}
                  </FilterTextMobile>
                </FilterLabelMobile>
              )
            })}
          </FilterWrapperMobile>
          {Object.entries(faqs).map((cat, i) => {
            return (
              <React.Fragment key={i}>
                {cat[0] === category ? (
                  <AccordionWrapper>
                    {faqs[cat[0]].map((faq, i) => {
                      return (
                        <FaqAccordion
                          key={i}
                          title={faq.question}
                          description={faq.answer}
                        ></FaqAccordion>
                      )
                    })}
                  </AccordionWrapper>
                ) : (
                  <></>
                )}
              </React.Fragment>
            )
          })}
        </FAQContainer>
      </FAQSection>
      <FooterSection>
        <Container>
          <FooterWrapper>
            <FooterContent>
              <FooterText>
                <FormattedMessage id="faq.footer_text" />
              </FooterText>
              <FooterButton to="/contact">
                <FormattedMessage id="faq.footer_cta" />
              </FooterButton>
            </FooterContent>
            <FooterIllustration src={paperPlane} />
          </FooterWrapper>
        </Container>
      </FooterSection>
      <Container>
        <ScrollToTop onClick={scrollTop}>
          <ScrollToTopText>
            <FormattedMessage id="faq.scroll_top" />
          </ScrollToTopText>
          <IconArrow src={arrow} alt="arrow" />
        </ScrollToTop>
      </Container>
    </Layout>
  )
}

export default FAQPage
